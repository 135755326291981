﻿import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

ScrollTrigger.matchMedia({

    "(min-width: 768px)": function () {
        const sections = gsap.utils.toArray("section");
        var refreshPriority = sections.length;
        sections.forEach(container => {
            if (container.classList.contains("cta-wimg-block")) {
                const scrollPin = gsap.utils.toArray(container.querySelectorAll(".scroll-pin"));
                const bar = gsap.utils.toArray(container.querySelectorAll(".yellow-bar-wrapper"))[0];

                const tl = gsap.timeline();
                tl.fromTo(bar,
                    {
                        xPercent: -100
                    },
                    {
                        duration: 2,
                        xPercent: 200
                    });


                ScrollTrigger.create({
                    animation: tl,
                    trigger: scrollPin,
                    start: "top center",
                    end: "bottom center",
                    scrub: 5,
                    refreshPriority: refreshPriority
                });
            }
            refreshPriority--;
        });
    }
});